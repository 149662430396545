/* Base App styles */
.App {
  text-align: center;
  height: 100vh; /* Ensure the app takes up the full viewport height */
  display: flex;
  flex-direction: column;
  background-color: white; /* White background for the whole page */
}

/* Header and Footer reduced height */
.App-header, .App-footer {
  background-color: #4E2D89; /* Primary theme color for header and footer */
  height: 8vh; /* Reduced height for a sleeker look */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-shrink: 0; /* Ensure they do not shrink */
}

/* Links in the header */
.App-link {
  color: #FF8C42; /* Orange color for hoverable links */
}

.App-link:hover {
  color: #ffffff; /* White on hover */
}

/* Main content container styling */
.main-content-container {
  flex-grow: 1; /* Allows the content area to expand and fill the available space */
  overflow-y: auto; /* Enables scrolling within the content area */
  background-color: white; /* Clean, white background for the content */
}

/* Content panel styling */
.content-panel {
  background-color: white; /* White background for professional look */
  padding: 30px; /* Padding for readability */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 8px; /* Rounded corners */
  width: 100%;
  max-width: 1200px; /* Control the max width of the content panel */
  margin: 20px auto; /* Centering the content panel */
  color: #333333; /* Dark gray text for readability */
}

/* Footer styling */
.footer {
  background-color: #4E2D89;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh; /* Reduced height for footer */
  flex-shrink: 0;
}

/* Button styling (with orange accent) */
.btn-primary {
  background-color: #FF8C42; /* Vibrant orange */
  border-color: #FF8C42; /* Matching border */
}

.btn-primary:hover {
  background-color: #e67e37; /* Slightly darker orange on hover */
  border-color: #e67e37;
}

/* Min height utilities */
.min-h-100 {
  height: 100px;
}

.min-h-200 {
  height: 200px;
}

/* Muted background colors for subtle use */
.bg-light-purple {
  background-color: #D8CCE2; /* Even lighter purple, for subtle background elements */
}

/* Text colors */
.text-dark {
  color: #333333; /* Dark gray for readability */
}


/* Scroll container for horizontally scrolling items */
.scroll-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping of items */
  overflow-x: auto;  /* Enable horizontal scroll */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  scroll-snap-type: x mandatory; /* Snap behavior for smooth scrolling */
}

.scroll-item {
  flex: 0 0 auto; /* Prevent the item from shrinking or growing */
  width: 300px;   /* Adjust the width of each tile */
  margin-right: 15px; /* Spacing between items */
  scroll-snap-align: start; /* Snap point for the items */
}

.scroll-item:last-child {
  margin-right: 0; /* No margin for the last item */
}

/* Optional: Hide the scrollbar for a cleaner look */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


/* Accordion item styling to match tile design */
.accordion-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px; /* Padding for better spacing */
}

.accordion-button {
  background-color: #fff;
  border: none; /* Remove default border */
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.accordion-button:not(.collapsed) {
  color: #007bff; /* Add blue color when expanded */
}

.accordion-body {
  color: #6c757d; /* Muted text color for body */
}

.accordion-header {
  border-bottom: none; /* Remove default bottom border */
}